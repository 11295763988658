import { formatCurrency } from "../../../../Utils/Utils";
import { paymentMethodTranslations, paymentStatusTranslation, TranslatePaymentMethod, TranslatePaymentStatus, TranslatePaymentType, TranslateTransactionType } from "../../../../Utils/TranslationUtils";
import useApi from "../../../../Utils/BackendClient";
import { useEffect, useRef, useState } from "react";

import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";

import commonStyle from "../../CommonStyles.module.css";
import { Chip } from "primereact/chip";
import { SplitButton } from "primereact/splitbutton";
import HistoryElement from "../../../UIElements/HistoryElement";

const Payments = () => {
    const toast = useRef();
    const buttonRef = useRef(null);
    const { fetchPaymentHistory, fetchPayments, fetchPaymentTransactions } = useApi();

    const [payments, setPayments] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [historyVisible, setHistoryVisible] = useState(false);
    const [history, setHistory] = useState(null);
    const [selectedActId, setSelectedActId] = useState(null);
    const [reloadData, setReloadData] = useState(true);
    const [historyTotalRecords, setHistoryTotalRecords] = useState(0);
    const [historyLazyState, setHistoryLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1
    });
    const [tableFilters, setTableFilters] = useState({
        cagent: { value: null, matchMode: 'equals' },
        method: { value: null, matchMode: 'equals' },
        status: { value: null, matchMode: 'equals' },
        date: { value: null, matchMode: 'btw' }
    });
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "date",
        sortOrder: 1,
        filters:{
            cagent: { value: null, matchMode: 'equals' },
            method: {value: null, matchMode: 'equals' },
            status: {value: null, matchMode: 'equals' },
            date: { value: null, matchMode: 'btw' }
        }
    });

    const dataFilterMatchModeOptions = [
        {
            label: "Між",
            value: "btw"
        }
    ]

    useEffect(() => {
        const fetchData = async () => {

            const response = await fetchPayments(lazyState);

            if (response.status === 200) {
                setTotalRecords(response.data.count);
                setPayments(response.data.results);
                console.log("Number of payments", response.data.count);
            }
            else{
                if(response.status === 403){
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку платежів", detail: "Користувач не має права перегляду списку платежів", life: 3000 });
                }
                else{
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку платежів", detail: "Помилка серверу " + response.status, life: 3000 });
                }
            }

            setLoading(false);
        }

        fetchData();

    }, [reloadData, lazyState, fetchPayments]);

    const getPaymentTransactions = (rowData) => {

        const fetchData = async () => {

            const response = await fetchPaymentTransactions(rowData.id);

            if (response.status === 200){
                setTransactions(response.data.results);
            }
            else
            {
                if(response.status === 403){
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку транзакцій", detail: "Користувач не має права перегляду списку платежів", life: 3000 });
                }
                else{
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку транзакцій", detail: "Помилка серверу " + response.status, life: 3000 });
                }
            }
        }

        fetchData();
        setVisible(true);
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const getHistory = async (id, state) => {

        const response = await fetchPaymentHistory(id, state);

        console.log(response);

        if (response.status === 200) {
            setHistory(response.data.results);
            setHistoryTotalRecords(response.data.count);
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const onHistoryPage = (event) => {
        event.page = event.page + 1;
        getHistory(selectedActId, event);

        setHistoryLazyState(event);
    }

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setTableFilters(event.filters);
    }

    const filterApply = () => {
        const editedLazyState = lazyState;
        editedLazyState.filters = tableFilters;
        setlazyState(editedLazyState);
        setReloadData(!reloadData)
    }

    const clearFilter = (paramName, value) => {
        const editedLazyState = lazyState;
        editedLazyState.filters[paramName].value = value;
        setlazyState(editedLazyState);
        setReloadData(!reloadData);
    }

    const clearFilterFromChips = (paramName, value) => {
        const editedLazyState = lazyState;
        editedLazyState.filters[paramName].value = value;
        setlazyState(editedLazyState);
        onFilter(editedLazyState);
        setReloadData(!reloadData);
    }

    const formatFilterChips = () => {
        const element = [];

        Object.keys(lazyState.filters).forEach((filterKey) => {
            const filter = lazyState.filters[filterKey];

            if (filter.value && filter.value !== "") {

                let translatedKey = filterKey;
                let changedValue = filter.value;
                let removeValue = null;

                if (filterKey === "method") {
                    translatedKey = "Метод";
                    changedValue = TranslatePaymentMethod(changedValue);
                }
                else if(filterKey === "status"){
                    translatedKey = "Статус";
                    let splittedValues = changedValue.toString().split(',');
                    for (let i = 0; i < splittedValues.length; i++) {
                        splittedValues[i] = TranslatePaymentStatus(splittedValues[i]);
                    }
                    changedValue = splittedValues.join(', ');
                }
                else if(filterKey === "date"){
                    translatedKey = "Дата";
                    let splittedValues = changedValue.toString().split(',');
                    for (let i = 0; i < splittedValues.length; i++) {
                        splittedValues[i] = new Date(splittedValues[i]).toLocaleDateString('ua-UA', { day: '2-digit', month: '2-digit', year: 'numeric'});
                    }
                    changedValue = splittedValues.join('-');
                }


                element.push(
                    <Chip
                        key={filterKey}
                        label={`${translatedKey}: ${changedValue}`}
                        removable
                        onRemove={() => clearFilterFromChips(filterKey, removeValue)} // Optional: To handle removal
                    />
                );
            }
        });

        return <div className="flex flex-wrap gap-2">{element}</div>;
    }

    const tableHeader = (
        <div className="flex justify-content-between align-items-center w-full">
            {formatFilterChips()}
        </div>
    );

    const paymentTypeTemplate = (rowData) => {
        return TranslatePaymentType(rowData.type);
    }

    const actionBodyTemplate = (rowData) => {
        const buttons = [
            {
                label: 'Історія',
                icon: 'pi pi-history',
                command: () => { 
                    setSelectedActId(rowData.id);
                    getHistory(rowData.id, historyLazyState);
                    setHistoryVisible(true); 
                }
            }
        ]
        return (
            <SplitButton
                label="Транзакції"
                icon="pi pi-eye"
                onClick={() => getPaymentTransactions(rowData)}
                model={buttons}
                text
                ref={buttonRef} // Set the ref here
            />
        );
    };

    const dateRowFilterTemplate = (options) => {
        return (
            <Calendar 
                value={options.value} 
                onChange={
                    (e) => options.filterApplyCallback(e.value)
                }
                dateFormat="dd.mm.yy"
                selectionMode="range"
                readOnlyInput 
                hideOnRangeSelection 
            />
        );
    };

    const statusRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={Object.entries(paymentStatusTranslation).map(([id, name]) => ({
                    label: name,
                    value: id
                }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label"
                maxSelectedLabels={1}
                selectedItemsLabel="{0} вибрано"
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const dropDownTemplate = (options, values) => {
        const dropdownOptions = Object.entries(values).map(([key, value]) => ({
            value: key,
            label: value,
        }));
    
        return (
            <Dropdown
                value={options.value}
                onChange={(e) => options.filterApplyCallback(e.value)}
                options={dropdownOptions}
                placeholder="Виберіть значення"
                className="p-column-filter"
            />
        );
    };

    return (<>
        <Toast ref={toast} />
        <div className='grid mt-3'>
            <div className='col-12 justify-content-center'>
                <DataTable value={payments} header={tableHeader}stripedRows lazy rows={lazyState.rows} paginator onPage={onPage}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} tableStyle={{ minWidth: '60rem' }}
                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder} dataKey="id" onFilter={onFilter} filters={lazyState.filters}>
                    <Column field="date" header="Дата" sortable filter filterElement={dateRowFilterTemplate} onFilterApplyClick={filterApply} filterMatchModeOptions={dataFilterMatchModeOptions}
                    onFilterClear={() => clearFilter("date", null)} body={(rowData) => new Date(rowData.date).toLocaleDateString('ua-UA', { day: '2-digit', month: '2-digit', year: 'numeric'})} style={{ width: '15%' }} />
                    <Column field="method" header="Метод" sortable filter filterElement={(options) => dropDownTemplate(options, paymentMethodTranslations)} onFilterApplyClick={filterApply}
                        onFilterClear={() => clearFilter("method", null)} showFilterMatchModes={false} body={rowData => TranslatePaymentMethod(rowData.method)} style={{ width: '10%' }} />
                    <Column field="type" header="Тип оплати" body={paymentTypeTemplate} style={{ width: '15%' }}/>     
                    <Column field="sum" header="Сума" 
                        body={(rowData) => {
                        const color = rowData.method === "INCOME" ? "#03C04A" : "#EF4444";
                        return (
                            <span style={{ color }}>
                            {formatCurrency(rowData.sum, rowData.currency)}
                            </span>
                        );}}
                        style={{ width: "10%" }}
                    />
                    <Column field="status" header="Статус оплати" sortable filter filterElement={statusRowFilterTemplate} onFilterApplyClick={filterApply}
                        onFilterClear={() => clearFilter("status", null)} showFilterMatchModes={false} body={rowData => TranslatePaymentStatus(rowData.status)} style={{ width: '10%' }} />
                    <Column field="cagent_name" header="Контрагент" style={{ width: '20%' }}/>
                    <Column field="undistributed_sum" header="Нерозподілена сума" body={(rowData) => formatCurrency(rowData.undistributed_sum, rowData.currency)} style={{ width: '10%' }}/>
                    <Column field="action" body={actionBodyTemplate} style={{ width: '10%' }}/>
                </DataTable>
            </div>
        </div>
        <Sidebar visible={visible} position="right" onHide={() => setVisible(false)} className={commonStyle.sidebarTable}>
            <h2>Список транзакцій</h2>
            <DataTable value={transactions} stripedRows >            
                <Column field="transaction_id" header="ID"/>
                <Column field="transaction_type" header="Тип" body={(rowData) => TranslateTransactionType(rowData.transaction_type)}/>
                <Column field="act_id" header="ID акту"/>  
                <Column field="distribution_sum" header="Розподілена сума" body={(rowData) => formatCurrency(rowData.distribution_sum, rowData.currency)} />
                <Column field="distribution_sum_UAH" header="Розподілена сума, грн" body={(rowData) => formatCurrency(rowData.distribution_sum_UAH, "UAH")} />
                <Column field="cagent_name" header="Контрагент" />
            </DataTable>
        </Sidebar>
        <Sidebar visible={historyVisible} position="right" onHide={() => setHistoryVisible(false)} className={commonStyle.sidebarTable}>
            <HistoryElement data={history} lazyState={historyLazyState} totalRecords={historyTotalRecords} onHistoryPage={onHistoryPage} isAct={false}/>
        </Sidebar>
    </>)
}

export default Payments;