import useAuthenticatedRequest from '../../Utils/TokenrRefreshUtils';
import { useContext, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';

import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

import { OrgInfoContext } from '../../context/OrgInfoProvider';

import { Avatar } from 'primereact/avatar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { FloatLabel } from 'primereact/floatlabel';
import { MegaMenu } from 'primereact/megamenu';
import { Menu } from 'primereact/menu';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';

import baseUserPic from "../../images/Random_user_pic.png";
import usFlag from "../../images/USD.svg";
import EUFlag from "../../images/EUR.svg";
import classes from "./PageBar.module.css";
import commonStyle from "./CommonStyles.module.css";


const PageBar = params => {

    const [userDialogVisible, setUserDialogVisible] = useState(false);
    const [showChangePasswordMenu, SetShowChangePasswordMenu] = useState(false);
    const [showUploadMenu, setShowUploadMenu] = useState(false);
    const [userLogo, setUserLogo] = useState();
    const [USDRate, setUSDRate] = useState();
    const [EURRate, setEURRate] = useState();
    const [USDDate, setUSDDate] = useState();
    const [EURDate, setEURDate] = useState();

    const orgctx = useContext(OrgInfoContext);
    const authenticatedRequest = useAuthenticatedRequest();
    const menu = useRef(null);

    const toast = useRef(null);

    useEffect(() => {
        const avatar = Cookies.get("avatar");
        setUserLogo(avatar !== null && avatar !== "" ? avatar : baseUserPic);
    }, []);

    useEffect(() => {


        if(Cookies.get("USDRate") === null || Cookies.get("USDRate") === undefined){

            const fetchData = async () => {
                const url = process.env.REACT_APP_BACKEND_URL + "currency/get_currencies_list/";
                const options = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };
                const response = await authenticatedRequest(url, options);
                const parsedResponse = await response.json();
    
                const USDObject = parsedResponse.find(rate => rate.currency_short === "USD");
                const EURObject = parsedResponse.find(rate => rate.currency_short === "EUR");
    
                setUSDRate(USDObject.currency_rate);
                Cookies.set("USDRate", USDObject.currency_rate);
                setUSDDate(USDObject.last_rate_date);
                Cookies.set("USDDate", USDObject.last_rate_date);
                setEURRate(EURObject.currency_rate);
                Cookies.set("EURRate", EURObject.currency_rate);
                setEURDate(EURObject.last_rate_date);
                Cookies.set("EURDate", EURObject.last_rate_date);  
            };
    
            fetchData();
        }
        else{
            setUSDRate(Cookies.get("USDRate"));
            setUSDDate(Cookies.get("USDDate"));
            setEURRate(Cookies.get("EURRate"));
            setEURDate(Cookies.get("EURDate"));
        }
    }, [authenticatedRequest]);
    

    const location = useLocation();
    let paths = location.pathname.split('/').filter((path) => path !== '');
    const complexpathes = ["editproductgroup", "editproduct", "editgroup", "editservice", "currencyrates", "edituser", "editdivision", "editreception", "editcounteragent", "editsale", "edituserrole", "editproductsreturn", "editreturn"];

    if (paths.some(path => complexpathes.includes(path))) {

        const elementCount = paths.length;

        const newelement = paths[elementCount - 2];

        let newpath = paths.slice(0, elementCount - 2);

        newpath.push(newelement);

        paths = newpath;
    }

    const translation = {
        "widgets": "Віджети",
        "productgroups": "Групи товарів",
        "createproductgroup": "Створити групу товарів",
        "editproductgroup": "Редагування групи товарів",
        "products": "Перелік товарів",
        "createproduct": "Cтворити товар",
        "editproduct": "Редагування продукту",
        "servicegroups": "Перелік груп послуг",
        "createservicegroup": "Створити групу послуг",
        "editgroup": "Редагування групи послуг",
        "services": "Перелік послуг",
        "createservice": "Створити послугу",
        "editservice": "Редагування послуги",
        "productsreception": "Прийом товару",
        "newreception": "Новий акт прийому товарів",
        "editreception": "Редагування акту прийому",
        "productsmove": "Переміщення товарів",
        "newproductsmove": "Новий акт переміщення товарів",
        "leftovers": "Залишки",
        "distributorreturns": "Повернення постачальнику",
        "newreturn": "Новий акт повернення",
        "editreturn": "Редагування акту повернення",
        "writeoff": "Списання товарів",
        "newwriteoff": "Новий акт списання товарів",
        "productsreturn": "Повернення товару",
        "newproductsreturn": "Новий акт повернення товару",
        "editproductsreturn": "Деталі акту повернення",
        "sales": "Продажі",
        "newsale": "Нове замовлення",
        "editsale": "Деталі замовлення",
        "partners": "Постачальники",
        "clients": "Клієнти",
        "newcounteragent": "Новий контрагент",
        "editcounteragent": "Редагування контрагента",
        "transactions": "Транзакції",
        "payments": "Платежі",
        "organization": "Налаштування організації",
        "users": "Користувачі",
        "newuser": "Новий користувач",
        "edituser": "Редагування користувача",
        "roles": "Ролі користувача",
        "newuserrole": "Нова роль користувача",
        "edituserrole": "Редагування ролі",
        "divisions": "Ієрархія підрозділів",
        "editdivision": "Редагування підрозділу",
        "newdivision": "Створення підрозділу",
        "currencies": "Валюта",
        "currencyrates": "Історія курсів валюти"
    };

    const getTransalation = path => {

        let returnTranslation = translation[path];

        complexpathes.forEach(element => {
            if (path.startsWith(element)) {
                const names = path.split(" ");

                returnTranslation = translation[names[0]];
            }
        });

        return returnTranslation;
    }

    const items = paths.map((path, index) => ({
        label: getTransalation(path),
        url: `/${paths.slice(0, index + 1).join('/')}`,
    }));

    const home = { icon: 'pi pi-home', url: '/widgets' };

    const startElement = (<div className='flex'>
        <Button icon="pi pi-bars" severity="secondary" outlined className="mr-2" onClick={() => params.changeNavBarState()} />
        <BreadCrumb model={items} home={home} className={classes.breadcrumb} />
    </div>
    );

    const decodedToken = jwtDecode(Cookies.get("authToken"));

    const dialogFormik = useFormik({
        initialValues: {
            old_password: '',
            password: '',
            password2: ''
        },
        validate: values => {
            const errors = {};

            if (values.old_password === null || values.old_password === "") {
                errors.old_password = "Поле не повинне бути пустим";
            }

            if (values.password === null || values.password === '') {
                errors.password = "Поле не повинне бути пустим";
            }
            if (values.password2 === null || values.password2 === '') {
                errors.password2 = "Поле не повинне бути пустим";
            }

            if (values.password !== values.password2) {
                errors.password2 = "Паролі не співпадають";
            }

            return errors;
        },
        onSubmit: values => {
            console.log(values);

            const changeUserPassword = async () => {

                const url = process.env.REACT_APP_BACKEND_URL + "auth/change_password/" + decodedToken.user_id + "/";
                const options = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                };

                const response = await authenticatedRequest(url, options);
                console.log(response);

                if (response.status === 200) {
                    SetShowChangePasswordMenu(false);
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Пароль успішно змінено', life: 3000 });
                    dialogFormik.resetForm();
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Помилка при зміні паролю', life: 3000 });
                }
            }

            changeUserPassword();
        }
    });

    const handleUserClick = () => {
        setUserDialogVisible(true);
    }

    const ShowChangePasswordMenu = () => {
        SetShowChangePasswordMenu(true);
    };

    const HideChangePasswordMenu = () => {
        SetShowChangePasswordMenu(false);
    };

    const HideDialogMessageHandler = () => {
        setShowUploadMenu(false);
        setUserDialogVisible(false);
    }

    const handleLogout = () => {
        setUserDialogVisible(false);
        Cookies.remove('authName');
        Cookies.remove('USDRate');
        orgctx.cleanOrgInfo();
        params.setUnloginned(true);
    };

    const DeleteLogoHandler = async () => {

        let formData = new FormData();
        formData.append('user_id', decodedToken.user_id.toString())

        const url = process.env.REACT_APP_BACKEND_URL + "auth/delete_logo/";
        const options = {
            method: "DELETE",
            body: formData,
            redirect: "follow"
        };

        const response = await authenticatedRequest(url, options);
        Cookies.remove("avatar");
        setUserLogo(baseUserPic);
        console.log(response);
    }

    const Upload = uploadEvent => {

        const uploadAvatar = async () => {

            let formData = new FormData();
            formData.append('user_id', decodedToken.user_id.toString())
            formData.append('logo', uploadEvent.files[0], uploadEvent.files[0].name)

            const UploadLogo = async (url, method) =>{

                const backEndUrl = process.env.REACT_APP_BACKEND_URL + "auth/" + url;
                const options = {
                    method: method,
                    body: formData,
                    redirect: "follow"
                };

                const response = await authenticatedRequest(backEndUrl, options);
                console.log(response);
                const parsedResponse = await response.json();
                console.log(parsedResponse);

                Cookies.set("avatar", parsedResponse.logo_url);
                setUserLogo(parsedResponse.logo_url);
            }

            if(Cookies.get("avatar") === null || Cookies.get("avatar") === undefined){
                UploadLogo("upload_logo/", "POST")
            }
            else{
                UploadLogo("update_logo/", "PUT")
            }
        }

        uploadAvatar();
        setShowUploadMenu(false);
    }

    const fullName = decodedToken.first_name + " " + decodedToken.last_name;

    const menuItems = [
        {
            label: 'Деталі користувача',
            icon: 'pi pi-user',
            command: () => {
                handleUserClick();
            },
        },
        {
            label: 'Вийти',
            icon: 'pi pi-sign-out',
            command: () => {
                handleLogout();
            },
        },
    ];

    const rightBlock = (<div className={`${classes["flex-container"]}`}>
        <div className={`${classes["flex-item"]}`}>
            <div>
                <img src={usFlag} alt="US Flag" width="20" height="15" />
                <span className="text-400 font-medium ml-2">USD</span>
                <div className="text-900 font-medium text-xl">{USDRate}</div>
            </div>
            <span>{USDDate}</span>
        </div>
        <div className={`${classes["flex-item"]}`}>
            <div>
                <img src={EUFlag} alt="EU Flag" width="20" height="15" />
                <span className="text-500 font-medium ml-2">EUR</span>
                <div className="text-900 font-medium text-xl">{EURRate}</div>
            </div>
            <span>{EURDate}</span>
        </div>

        <div className={`${classes["flex-item"]} ${classes.userBlock}`}>

            <Avatar
                image={userLogo}
                size="large"
                style={{ cursor: 'pointer' }}
                onClick={(e) => menu.current.toggle(e)} // Toggle menu on click
            />
            <Menu
                model={menuItems}
                popup
                ref={menu}
            />
        </div>
    </div>);

    return <div>
        <Toast ref={toast} position="top-center" />
        <MegaMenu orientation="horizontal" start={startElement} end={rightBlock} className={classes.borderless} />
        <Sidebar
            visible={userDialogVisible}
            position='right'
            onHide={HideDialogMessageHandler}
        >
        <div className="grid">
                <div className="col-12">
                    <div className="text-center">
                        <img
                            className="rounded-circle"
                            width="150px"
                            src={userLogo}
                            alt="User Avatar"
                        />
                    </div>
                    {!showUploadMenu && <div className="text-center">
                        <Tooltip target=".custom-changelogo-btn" content="Змінити лого" position="left" />
                        <Tooltip target=".custom-deletelogo-btn" content="Видалити лого" position="right" />
                        <Button icon="pi pi-arrow-right-arrow-left" className={`p-button-rounded p-button-danger ${commonStyle.editButton} mt-2 custom-changelogo-btn`} onClick={() => setShowUploadMenu(true)} />
                        <Button icon="pi pi-trash" className={`p-button-rounded p-button-danger ${commonStyle.deleteButton} custom-deletelogo-btn ml-1`} onClick={DeleteLogoHandler} type="button" />
                    </div>}
                    {showUploadMenu && <div className="text-center mt-3">
                        <FileUpload
                            mode="basic"
                            name="demo[]"
                            accept="image/*"
                            maxFileSize={1000000}
                            customUpload
                            uploadHandler={Upload}
                        >
                        </FileUpload>
                    </div>}
                </div>
                <div className="col-12">
                    <div className='flex flex-column align-items-center justify-content-center'>
                        <h2 className='mb-0'>{fullName} </h2>
                        {decodedToken.position !== undefined && <h4>{decodedToken.position}</h4>}
                        <span className='mb-4'>{decodedToken.email}</span>
                    </div>
                    {!showChangePasswordMenu && (<div  className='flex align-items-center justify-content-center'> 
                        <Button label="Змінити пароль" severity="info" className={`${commonStyle.editButton} ${commonStyle.inputTextStyle}`} onClick={ShowChangePasswordMenu} />
                    </div>)}
                    {showChangePasswordMenu && (<form onSubmit={dialogFormik.handleSubmit} style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <div className={commonStyle.dialogFormInput}>
                                <div className="p-inputgroup flex-column md:w-17rem">
                                    <FloatLabel>
                                        <InputText
                                            name="old_password"
                                            value={dialogFormik.values.old_password}
                                            onChange={(e) => { dialogFormik.setFieldValue('old_password', e.target.value) }}
                                            className={dialogFormik.errors.old_password && dialogFormik.touched.old_password ? 'p-invalid' : ''}
                                        />
                                        <label>Старий пароль</label>
                                    </FloatLabel>
                                    {dialogFormik.errors.old_password && dialogFormik.touched.old_password && (<small className={commonStyle.errorSmall}>{dialogFormik.errors.old_password}</small>)}
                                </div>
                            </div>
                            <div className={commonStyle.dialogFormInput}>
                                <div className="p-inputgroup flex-column md:w-17rem">
                                    <FloatLabel>
                                        <InputText
                                            name="password"
                                            value={dialogFormik.values.password_password}
                                            onChange={(e) => { dialogFormik.setFieldValue('password', e.target.value) }}
                                            className={dialogFormik.errors.password && dialogFormik.touched.password ? 'p-invalid' : ''}
                                        />
                                        <label>Новий пароль</label>
                                    </FloatLabel>
                                    {dialogFormik.errors.password && dialogFormik.touched.password && (<small className={commonStyle.errorSmall}>{dialogFormik.errors.password}</small>)}
                                </div>
                            </div>
                            <div className={commonStyle.dialogFormInput}>
                                <div className="p-inputgroup flex-column md:w-17rem">
                                    <FloatLabel>
                                        <InputText
                                            name="password2"
                                            value={dialogFormik.values.password2}
                                            onChange={(e) => { dialogFormik.setFieldValue('password2', e.target.value) }}
                                            className={dialogFormik.errors.password2 && dialogFormik.touched.password2 ? 'p-invalid' : ''}
                                        />
                                        <label>Підтвердіть новий пароль</label>
                                    </FloatLabel>
                                    {dialogFormik.errors.password2 && dialogFormik.touched.password2 && (<small className={commonStyle.errorSmall}>{dialogFormik.errors.password2}</small>)}
                                </div>
                            </div>
                            <div className="flex flex-column align-items-center justify-content-center">
                                <Button label="Змінити пароль" type="submit" severity="success" className={`${commonStyle.addButton} mt-2 ${commonStyle.inputTextStyle}`} />
                                <Button label="Відмінити" type="button" severity="info" className={`${commonStyle.editButton} mt-1 ${commonStyle.inputTextStyle}`} onClick={HideChangePasswordMenu} />
                            </div>
                        </div>
                    </form>)}
                </div>
            </div>
            <div className="flex align-items-center justify-content-center">
                <Button label="Вийти" className={`${commonStyle.deleteButton} ${commonStyle.inputTextStyle}`} onClick={handleLogout} />
            </div>
        </Sidebar>
    </div>;
};

export default PageBar;