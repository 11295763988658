import useApi from "../../../../Utils/BackendClient";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { convertSumWithLatestCurrencyRate, formatCurrency, systemCurrencies } from "../../../../Utils/Utils";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner"
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";

import commonStyle from "../../CommonStyles.module.css";
import { Tree } from "primereact/tree";
import { Chip } from "primereact/chip";

const LeftOvers = () => {
    
    const navigate = useNavigate();
    const toast = useRef(null);
    const { fetchProductsExtended, fetchRemainingQRCode, fetchRemainings, updateProductPrice, } = useApi();

    const [productCategoriesLoading, setProductCategoriesLoading] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [leftovers, setLeftOvers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [changePriceSiderBarVisible, setChangePriceSiderBarVisible] = useState(false);
    const [saleItem, setSaleItem] = useState([]);
    const [reloadData, setReloadData] = useState(false);
    const [productHierarcy, setProductHierarchy] = useState([]);
    const [tableFilters, setTableFilters] = useState({
        product_group: { value: null, matchMode: 'equals', name: '' },
        product_name: {value: "", matchMode: 'equals'},
    });
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 20,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            product_group: { value: null, matchMode: 'equals', name: '' },
            product_name: {value: "", matchMode: 'equals'},
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchProductsExtended();

            if (response.status !== 200) return;

            const buildHierarchy = (groups) => {
                return groups
                    .filter(group => group.is_active)
                    .map(group => {
                        const groupBlock = {
                            key: group.id,
                            label: group.group_name,
                            droppable: false,
                            children: group.sub_groups.length > 0 ? buildHierarchy(group.sub_groups) : undefined,
                        };

                        return groupBlock;
                    });
            };

            const hierarchy = buildHierarchy(response.data || []);
            console.log(hierarchy);

            setProductHierarchy(hierarchy);
            setProductCategoriesLoading(false);
        };

        fetchData();
    }, [fetchProductsExtended]);

    useEffect(() => {

        const fetchData = async () => {

            const response = await fetchRemainings(lazyState);

            if (response.status === 200) {
                setTotalRecords(response.data.count);
                setLeftOvers(response.data.results);
                setLoading(false);
            }
        }

        fetchData();
    }, [lazyState, fetchRemainings, reloadData]);

    const sideBarFormik = useFormik({
        initialValues: saleItem,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.quantity === null || values.quantity === undefined || values.quantity === "") {
                errors.quantity = "Введіть кількість";
            }

            return errors;
        },
        onSubmit: (values) => {
            setVisible(false);
            createNewSale(values);
        }
    });

    const changePriceFormik = useFormik({
        initialValues: {
            remaining_id: "",
            accounting_type: "",
            product_name: "",
            use_product_sale_price: "",
            custom_sale_price_currency: "",
            custom_sale_price: "",
        },
        enableReinitialize: true,
        validate: values => {
            const errors = {};
            return errors
        },
        onSubmit: (values) => {

            const changePrice = async () => {

                const response = await updateProductPrice(values);

                if (response.status !== 200) {
                    toast.current.show({ severity: "error", summary: "Помилка зміна ціни товару", detail: "Помилка сервера " + response.status, life: 3000 });
                }
                else {
                    toast.current.show({ severity: "success", summary: 'Зміна ціни', detail: 'Ціна товару успішно змінена', life: 3000 });
                }

                setChangePriceSiderBarVisible(false);
                setReloadData(!reloadData);
            }

            changePrice();
        }
    });

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setTableFilters(event.filters);
    }

    const applyFilterFromMenu = (node) => {

        console.log(node);
        const filters = tableFilters;
        filters.product_group.value = node.key;
        filters.product_group.name = node.label;
        setTableFilters(filters);

        const state = lazyState;
        state.filters.product_group.value = node.key;
        state.filters.product_group.name = node.label;
        setlazyState(state);

        setReloadData(!reloadData);
    }

    const filterApply = () => {
        const editedLazyState = lazyState;
        editedLazyState.filters = tableFilters;
        setlazyState(editedLazyState);
        setReloadData(!reloadData)
    }

    const clearFilter = (paramName, value) => {
        const editedLazyState = lazyState;
        editedLazyState.filters[paramName].value = value;
        setlazyState(editedLazyState);
        setReloadData(!reloadData);
    }

    const changePriceButtonHandler = rowData => {
        changePriceFormik.setFieldValue('remaining_id', rowData.id);
        changePriceFormik.setFieldValue('product_name', rowData.product_name);
        changePriceFormik.setFieldValue('accounting_type', rowData.identifier === undefined ? "PIECE" : "INDIVIDUAL");
        changePriceFormik.setFieldValue('use_product_sale_price', rowData.use_product_sale_price);
        changePriceFormik.setFieldValue('custom_sale_price_currency', rowData.sale_price_currency);
        changePriceFormik.setFieldValue('custom_sale_price', rowData.sale_price);
        setChangePriceSiderBarVisible(true);
    }

    const printPriceTag = products => {

        const fetchData = async () => {

            let request = [];
            products.forEach(product => {
                const element = {
                    remaining_id: product.id,
                    accounting_type: product.identifier === undefined ? "PIECE" : "INDIVIDUAL"
                }
                request.push(element);
            });

            const response = await fetchRemainingQRCode(request);
            if (response.status === 200) {
                console.log(response);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;

                // Set a filename
                link.download = `QR_codes.pdf`; // Change based on your needs
                document.body.appendChild(link);
                link.click();

                // Clean up
                link.remove();
                window.URL.revokeObjectURL(url);

                setSelectedProducts(null);
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Помилка завантаження', detail: 'Помилка завантадення цінника', life: 3000 });
            }
        }

        fetchData()
    }

    const clearFilterFromChips = (paramName, value) => {
        const editedLazyState = lazyState;
        editedLazyState.filters[paramName].value = value;
        setlazyState(editedLazyState);
        onFilter(editedLazyState);
        setReloadData(!reloadData);
    }

    const formatFilterChips = () => {
        const element = [];

        Object.keys(lazyState.filters).forEach((filterKey) => {
            const filter = lazyState.filters[filterKey];

            if (filter.value && filter.value !== "") {

                let translatedKey = filterKey;
                let changedValue = filter.value;
                let removeValue = null;


                if (filterKey === "product_group") {
                    translatedKey = "Назва групи";
                    changedValue = filter.name;
                }
                else if(filterKey === "product_name") {
                    translatedKey = "Назва товару";
                    removeValue = "";
                }
                else if (filterKey === "division") {
                    translatedKey = "Підрозділ";

                }

                element.push(
                    <Chip
                        key={filterKey}
                        label={`${translatedKey}: ${changedValue}`}
                        removable
                        onRemove={() => clearFilterFromChips(filterKey, removeValue)} // Optional: To handle removal
                    />
                );
            }
        });

        return <div className="flex flex-wrap gap-2">{element}</div>;
    }

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <div className="flex align-items-center justify-content-start gap-2">
                {formatFilterChips()}
            </div>
            {(selectedProducts && selectedProducts.length > 0) && <Button
                className={commonStyle.warningTag}
                severity="warning"
                tooltipOptions={{ position: "top" }}
                onClick={() => printPriceTag(selectedProducts)}
            >
                Друкувати стікери
            </Button>}
        </div>
    );

    const UAHPriceTemplate = (rowData) => {
        return formatCurrency(convertSumWithLatestCurrencyRate(rowData.sale_price_currency, rowData.sale_price), "UAH");
    }

    const newSaleButtonHandler = rowData => {
        if (rowData.identifier !== undefined) {
            createNewSale(rowData);
        }
        else {
            rowData.isNew = true;
            rowData.quantity = 1;
            setSaleItem(rowData);
            setVisible(true);
        }
    }

    const createNewSale = rowData => {
        const route = '/sales/newsale/';
        navigate(route, { state: { item: rowData } });
    }

    const actionBodyTemplate = rowData => {
        return (<>
            <Button
                icon="pi pi-shopping-cart"
                className={`p-button-rounded p-button-success ${rowData.total_quantity !== rowData.booked ? commonStyle.addButton : commonStyle.closeButton}`}
                tooltip={rowData.total_quantity !== rowData.booked ? "Створити акт" : "Товари зарезервовані"}
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                disabled={rowData.total_quantity === rowData.booked}
                onClick={() => newSaleButtonHandler(rowData)}
            />
            <Button
                icon="pi pi-pencil"
                className={`p-button-rounded p-button-info ${rowData.total_quantity !== rowData.booked ? commonStyle.editButton : commonStyle.closeButton}`}
                tooltip={rowData.total_quantity !== rowData.booked ? "Редагувати ціну" : "Товари зарезервовані"}
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                disabled={rowData.total_quantity === rowData.booked}
                onClick={() => changePriceButtonHandler(rowData)}
            />
            <Button
                icon="pi pi-barcode"
                className={`p-button-rounded p-button-warning ${commonStyle.warningTag}`}
                tooltip="Друкувати цінники"
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                onClick={() => printPriceTag([rowData])}
            />
        </>)
    }

    //filter templates
    const inputTemplate = (options) => {
        return (
            <InputText
                value={options.value}
                onChange={
                    (e) => options.filterApplyCallback(e.target.value)
                }
            />
        );
    }

    return (<>
        <Toast ref={toast} />
        <div className="grid">
            <div className={`col-2 xlg:col-3 ${productCategoriesLoading ? "flex align-items-center justify-content-center" : ""}`} style={{ height: '100vh' }}>
                {productCategoriesLoading ? (
                    <ProgressSpinner />
                ) : (
                    <Tree
                        value={productHierarcy}
                        selectionMode="single"
                        dragdropScope="demo"
                        onDragDrop={(e) => setProductHierarchy(e.value)}
                        onSelect={e => applyFilterFromMenu(e.node)}
                        className="w-full"
                        filter 
                        filterMode="lenient" 
                        filterPlaceholder="Пошук групи"
                    />
                )}
            </div>
            <div className="col-10 xlg:col-9">
                <DataTable value={leftovers} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} header={header}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]}
                    selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder} 
                    onFilter={onFilter} filters={tableFilters} 
                    emptyMessage="Товарів не знайдено" tableStyle={{ minWidth: '60rem' }} >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                    <Column field="product_name" header="Товар" sortable filter filterElement={inputTemplate} onFilterApplyClick={filterApply} onFilterClear={() => clearFilter("product_name", "")} showFilterMatchModes={false} />
                    <Column field="note" header="Примітка" />
                    <Column field="division_name" header="Підрозділ" />
                    <Column field="identifier" header="Ідентифікатор" />
                    <Column field="total_quantity" header="Кількість" sortable />
                    <Column field="booked" header="Зарезервовано" sortable />
                    <Column field="sale_price" header="Вартість продажу" body={(rowData) => formatCurrency(rowData.sale_price, rowData.sale_price_currency)}></Column>
                    <Column field="sale_price" header="Вартість продажу, грн" body={UAHPriceTemplate}></Column>
                    <Column field="action" style={{ width: '15%' }} body={actionBodyTemplate} />
                </DataTable>
            </div>
            <Sidebar visible={visible} position="right" onHide={() => setVisible(false)} className={commonStyle.shortSideBar}>
                <form onSubmit={sideBarFormik.handleSubmit}>
                    <div className={commonStyle.sideBarInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="quantity"
                                    value={sideBarFormik.values.quantity}
                                    onChange={(e) => { sideBarFormik.setFieldValue('quantity', e.target.value) }}
                                    className={sideBarFormik.errors.quantity && sideBarFormik.touched.quantity ? 'p-invalid' : ''}
                                />
                                <label>Кількість</label>
                            </FloatLabel>
                            {sideBarFormik.errors.quantity && sideBarFormik.touched.quantity && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.quantity}</small>)}
                        </div>
                    </div>
                    <div>
                        <Button label="Створити акт" severity="success" type="submit" className={`${commonStyle.sideBarButton} ${commonStyle.addButton}`} autoFocus />
                    </div>
                </form>
            </Sidebar>
            <Sidebar visible={changePriceSiderBarVisible} position="right" onHide={() => setChangePriceSiderBarVisible(false)} className={commonStyle.shortSideBar}>
                <form onSubmit={changePriceFormik.handleSubmit}>
                    <div className={commonStyle.sideBarInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="product_name"
                                    value={changePriceFormik.values.product_name}
                                    onChange={(e) => { changePriceFormik.setFieldValue('product_name', e.target.value) }}
                                    disabled
                                />
                                <label>Назва товару</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className={commonStyle.sideBarInput}>
                        <div className={`${commonStyle.checkboxStyle} p-inputgroup flex-1"`}>
                            <Checkbox
                                name="use_product_sale_price"
                                onChange={(e) => { changePriceFormik.setFieldValue('use_product_sale_price', !changePriceFormik.values.use_product_sale_price) }}
                                checked={changePriceFormik.values.use_product_sale_price}
                            />
                            <label>Використовувати ціну продажу з довідника</label>
                        </div>
                    </div>
                    {!changePriceFormik.values.use_product_sale_price && <>
                        <div className={commonStyle.sideBarInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <Dropdown value={changePriceFormik.values.custom_sale_price_currency}
                                        onChange={(e) => changePriceFormik.setFieldValue('custom_sale_price_currency', e.value)}
                                        options={systemCurrencies}
                                        showClear
                                        className={changePriceFormik.errors.custom_sale_price_currency && changePriceFormik.touched.custom_sale_price_currency ? 'p-invalid w-full' : "w-full"} />
                                    <label>Валюта продажу</label>
                                </FloatLabel>
                                {changePriceFormik.errors.custom_sale_price_currency && changePriceFormik.touched.custom_sale_price_currency && (<small className={commonStyle.errorSmall}>{changePriceFormik.errors.custom_sale_price_currency}</small>)}
                            </div>
                        </div>
                        <div className={commonStyle.sideBarInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <InputText
                                        name="custom_sale_price"
                                        value={changePriceFormik.values.custom_sale_price}
                                        onChange={(e) => { changePriceFormik.setFieldValue('custom_sale_price', e.target.value) }}
                                        className={changePriceFormik.errors.custom_sale_price && changePriceFormik.touched.custom_sale_price ? 'p-invalid' : ''}
                                    />
                                    <label>Ціна продажу в валюті розрахунку, за одиницю</label>
                                </FloatLabel>
                                {changePriceFormik.errors.custom_sale_price && changePriceFormik.touched.custom_sale_price && (<small className={commonStyle.errorSmall}>{changePriceFormik.errors.custom_sale_price}</small>)}
                            </div>
                        </div>
                    </>}
                    <div>
                        <Button label="Змінити ціну" severity="success" type="submit" className={`${commonStyle.sideBarButton} ${commonStyle.addButton}`} autoFocus />
                    </div>
                </form>
            </Sidebar>
        </div>
    </>);
}

export default LeftOvers;